:root {
    --light-grey: #F6F9FC;
    --dark-terminal-color: #0A2540;
    --accent-color: #635BFF;
    --radius: 3px;
    --all-icons: url(../../assets/images/lexi-nation-all-icon.png)
}

.Content {
    margin-top: 72px;
}

.formError {
    color: #ff0066;
    font-size: x-small;
}



#payment-form {
    border: #F6F9FC solid 1px;
    border-radius: var(--radius);
    padding: 20px;
    margin: 0 auto;
    width: 500px;
    box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

#payment-message {
    /* display: none; */
    /* hide initially, then show once the first message arrives */
    background-color: #0A253C;
    color: #00D924;
    padding: 10px;
    margin: 5px 0;
    border-radius: var(--radius);
    font-size: 0.7em;
}

body {
    font-family: Helvetica, Sans-Serif;

}

.bg-theme1 {
    background-color: #5b8a39;
}

.text-theme1 {
    color: #5b8a39;
}

.font-14 {
    font-size: 14px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.fontw-500 {
    font-weight: 500;
}

.fontw-600 {
    font-weight: 600;
}

.pt-18 {
    padding-top: 18px !important;
    display: inline-block;
}

.mb-90 {
    margin-bottom: 90px;
}

/******************
1. header start 
*****************/
.main-page {
    min-height: 100vh;
}

.main-page-inner {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

}

.navbar-light .navbar-nav .nav-link:hover {
    color: #fff !important;
}

.navbar-nav li>a {
    color: #000 !important;
}

.navbar-nav li>a.active {
    background-color: #000;
    color: #fff !important;
    border-radius: 6px;
}

.main-menu>li:hover>a {
    color: #fff !important;
}

header>.top-bar {
    padding-top: 5px;
    padding-bottom: 5px;
}

.header-top-email>a {
    background-image: var(--all-icons);
    background-position: 1px 2px;
    background-repeat: no-repeat;
    padding: 0px;
    width: 32px;
    height: 30px;
    background-size: auto;
    text-align: center;
    display: flex;
    align-items: center;

}

.header-top-bar-btn>button {
    border: 1px solid #9db988;
    font-size: 14px;
    color: #fff;
}

.header-top-bar-btn>button:nth-child(1) {
    background-image: var(--all-icons);
    background-position: -49px 1px;
    background-repeat: no-repeat;
    padding: 0px;
    width: 100px;
    height: 30px;
    padding-left: 30px;
    background-size: auto;
    text-align: center;
    transition: all 0.4s ease;

}

.header-top-bar-btn>button:hover {
    color: #fff;
    background-color: #000;
    border: 1px solid #000;

}

.header-top-bar-btn>button:nth-child(2) {
    background-image: var(--all-icons);
    background-position: -49px 1px;
    background-repeat: no-repeat;
    padding: 0px;
    width: 100px;
    height: 30px;
    padding-left: 30px;
    background-size: auto;
    text-align: center;
}

.header-call-btn {
    background-image: var(--all-icons);
    background-position: -404px -40px;
    background-repeat: no-repeat;
    width: 100px;
    height: 30px;
    padding: 5px 12px 5px 30px;
    background-size: auto;
    text-align: center;
}

.header-call-btn {
    background-color: #fff;
    color: #5b8a39;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 600;
    vertical-align: middle;
    transition: all 0.4s ease;
}

.header-call-btn:hover {
    color: #fff;
    background-color: #000;

}

/* menu start */
.main-menu>li>a {
    transition: all 0.4s ease;
}

.main-menu>li.active>a,
.main-menu>li:hover>a {
    background: black !important;
    color: #fff !important;
    border-radius: 4px;
}

.main-menu>li:hover a.bookbtn {
    background: inherit;
    color: inherit;
    border-radius: inherit;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
}

/*.header li {
    display: inline-block;
    margin: 0;
    padding: 0;
}*/
.nav {
    --bs-nav-link-padding-x: inherit;
    --bs-nav-link-padding-y: inherit;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: #6c757d;
    display: inherit;
    flex-wrap: wrap;
    padding-left: inherit;
    margin-bottom: inherit;
    list-style: inherit;
}

.header li a {
    display: block;
    padding: 6px 10px;
    text-decoration: none;
    color: #000;
}

.bookbtn {
    margin-top: -7px;
    padding-right: 0px !important;
}

.main-menu-bookbtn>button,
.main-menu-bookbtn>button:hover {
    background-color: #000;
    color: #fff;
    height: 36px;
    font-size: 22px;
}

.header .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding: 10px 20px;
    text-decoration: none;
}

/* menu */

.header .menu {
    clear: both;
    max-height: 0px;
    transition: max-height .2s ease-out;
}

/* menu icon */
.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 0px;
    position: relative;
    user-select: none;
}

.header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 4px;
    position: relative;
    transition: background .2s ease-out;
    width: 33px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 8px;
}

.header .menu-icon .navicon:after {
    top: -8px;
}

/* menu btn */

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked~.menu {
    max-height: 240px;
}

.header .menu-btn:checked~.menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked~.menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.header .menu-btn:checked~.menu-icon .navicon:after {
    transform: rotate(45deg);
}

.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
    top: 0;
}

/******************
2. home banner start 
*****************/
.home-banner {
    background-image: url(../../assets/images/home-banner.jpg);
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 40px;
    background-size: cover;
}

.home-banner-aboutus {
    background: rgba(0, 0, 0, .39);
    padding: 15px 15px;
}

.home-banner-aboutus>h1 {
    font-size: clamp(1.50rem, 4vw, 2.75rem);
    font-family: 'Bogart Trial', sans-serif;
}


.home-banner-aboutus>p {
    font-size: 18px;
}

.home-banner-bookblock {
    /* border: 5px solid #00D924; */
    background: rgba(0, 0, 0, .39);
    padding: 50px 15px 54px 15px;
}

.home-banner-bookblock>h1 {
    font-size: clamp(1.50rem, 4vw, 2.75rem);
    font-family: 'Bogart Trial', sans-serif;
}


.home-banner-bookblock>p {
    font-size: 18px;
}

.home-banner-form {
    padding: 15px 15px 30px;
    border-radius: 4px;
}

.home-banner-form>h2 {
    font-weight: 600;
    font-size: clamp(1.50rem, 6vw, 1.75rem);
}

.home-form-label {
    font-size: 20px;

}

.home-banner-date>span {
    background-color: inherit;
    border: 1px solid #5b8b3994;
}

.home-banner-date>input {
    background-color: inherit;
    border: 1px solid #5b8b3994;
    height: 36px;
    padding: 0px 6px;
}

.home-banner-date input::placeholder {
    font-size: 14px;
    color: #8d8d8d;
}

.home-banner-date>span {
    background-image: var(--all-icons);
    background-position: -2px -217px;
    background-repeat: no-repeat;
    width: 40px;
    height: 36px;
    padding-left: 25px;
    background-size: auto;
    text-align: center;
}

.home-banner-start-time>span,
.home-banner-type-pet>span,
.home-banner-pet-name>span,
.home-banner-pet-name>input,
.home-banner-pet-needs>span,
.home-banner-number-hour>span,
.home-banner-pet-needs>input,
.home-banner-date>span {
    background-color: inherit;
    height: 36px;
    border: 1px solid #5b8b3994;
}

.home-banner-start-time>.form-select,
.home-banner-number-hour>.form-select,
.home-banner-type-pet>.form-select,
.home-banner-pet-name>input,
.home-banner-pet-needs>input,
.home-banner-date>.form-select {
    font-size: 14px;
    padding: 0px 4px;
    background-color: inherit;
    color: #8d8d8d;
    border: 1px solid #5b8b3994;
}

.form-user-email input::placeholder {
    font-size: 13px;
}

.home-banner-start-time>span {
    background-image: var(--all-icons);
    background-position: -3px -247px;
    background-repeat: no-repeat;
    width: 40px;
    height: 36px;
    padding-left: 25px;
    background-size: auto;
    text-align: center;
}

.home-banner-number-hour>span {
    background-image: var(--all-icons);
    background-position: -3px -280px;
    background-repeat: no-repeat;
    width: 40px;
    height: 36px;
    padding-left: 25px;
    background-size: auto;
    text-align: center;
}

.home-banner-type-pet>span {
    background-image: var(--all-icons);
    background-position: -3px -314px;
    background-repeat: no-repeat;
    width: 40px;
    height: 36px;
    padding-left: 25px;
    background-size: auto;
    text-align: center;
}

.home-banner-pet-name>span {
    background-image: var(--all-icons);
    background-position: -2px -187px;
    background-repeat: no-repeat;
    width: 40px;
    height: 36px;
    padding-left: 25px;
    background-size: auto;
    text-align: center;
}

.home-banner-pet-needs>span {
    background-color: inherit;
}

.home-banner-pet-needs>span {
    background-image: var(--all-icons);
    background-position: -5px -44px;
    background-repeat: no-repeat;
    width: 40px;
    height: 36px;
    padding-left: 25px;
    background-size: auto;
    text-align: center;
}

.button {
    padding: 2rem 7rem;
    background: white;
    text-align: center;
    display: inline-block;
    font-size: 1.7rem;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    will-change: transform;
}








.book-date-btn span {
    position: relative;
    z-index: 1;
}

.book-date-btn {
    padding: 4px 10px;
    color: #ffff;
    border: 0px transparent;
    border-radius: 0.3rem;
    transition: 0.3s ease-in-out;
    transition-delay: 0.35s;
    overflow: hidden;
    background-color: #5b8a39;
    position: relative;
}

.book-date-cancel-btn {
    line-height: 20px;
    padding: 5px 10px 5px 10px;
    margin-bottom: 3px;

}

.book-date-btn:after {
    content: "";
    position: absolute;
    left: -37px;
    top: 0;
    height: 650%;
    width: 215%;
    background: #000;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
    transform: translateX(-98%) translateY(-25%) rotate(45deg);
}

.book-date-btn:hover:after {
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

/******************
3. facts start 
*****************/
.facts-img {
    position: relative;
}

.facts-img>img:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.facts-text>h2 {
    font-size: clamp(1.30rem, 3vw, 1.875rem);
}

/******************
4. services start 
*****************/
.services {
    padding: 5px 0px 115px;
    position: relative;
    overflow: hidden;
    margin-top: 119px;
}

/*.services:after {
    content: '';
    position: absolute;
    right: 0;
    background-image: url(../../assets/images/services-bg.png);
    width: 723px;
    height: 600px;
    top: 0;
    bottom: 0;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 100% -60%;
    z-index: -1;

}*/
.service-title {
    font-size: clamp(2.125rem, 5vw, 3.125rem);
    font-family: 'Bogart Trial', sans-serif;
    font-weight: 600;
}

.services>img {
    position: absolute;
    top: 0;
    right: 0;
}

.services-img {
    border-radius: 50%;
    width: 220px;
    height: 220px;
    margin: auto;
    border: 6px solid #a9dd85;
    overflow: hidden;
}

.services-wrap {
    width: 50%;
}

.services-content>h2 {
    font-size: clamp(1.125rem, 5vw, 1.375rem);
}

.services-content>a {
    padding: 12px 24px;
    margin-top: 5px;
    display: inline-block;
}

/******************
5. home page testimonial start 
*****************/
.testimonial-wrap {
    margin-bottom: 50px;
}

.slider-titile {
    font-size: clamp(2.125rem, 5vw, 2.5rem);
    font-family: 'Bogart Trial', sans-serif;
    color: #5b8b39;
    font-weight: 600;
    padding-top: 40px;
    padding-bottom: 20px;
}

.testimonial-content>p:nth-child(1),
.testimonial-content>p:nth-child(3) {
    font-size: 72px;
    color: #bfbfbf;
    margin-bottom: 5px;
    line-height: 0;
}

.testimonial-content>p:last-child {
    font-size: 22px;
    color: #000;
    margin-bottom: 5px;
    line-height: 0;
}

.owl-carousel-testimonial>.owl-dots {
    margin-top: 20px !important;
}

.owl-carousel-testimonial>.owl-dots>button>span {
    width: 50px !important;
    height: 8px !important;
    margin: 5px 7px;
    background: #D6D6D6;
    display: inline-block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}

.owl-carousel-testimonial>.owl-dots>button.active>span {
    width: 50px !important;
    height: 8px !important;
    margin: 5px 7px;
    background: #5b8b39 !important;
    display: inline-block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;

}

/******************
6. gallery start 
*****************/
.gallery-wrap {
    margin-bottom: 100px;
}

.gallery-title>h2 {
    font-size: clamp(2.125rem, 5vw, 2.75rem);
    font-family: 'Bogart Trial', sans-serif;
    font-weight: 600;
}

.gallery>li {
    overflow: hidden;
}

.gallery>li>a>img {
    transform: scale(1);
    transition: all 0.3s ease-in-out;
}

.gallery>li>a:hover>img {
    transform: scale(1.2);
}

.gallery>li>a {
    /* width: 310px; */
    height: 310px;
    border-radius: 25px;
    overflow: hidden;
    display: block;
}

.gallery-col-one {
    padding-top: 170px;
}

.gallery-col-two {
    padding-top: 120px;
}

.gallery-col-three {
    padding-top: 70px;
}

/******************
7. footer start 
*****************/
footer {
    position: relative;
}

.footer-bg {
    position: absolute;
    bottom: 224px;
    left: 0;
    right: 0;
    width: 100%;
}

footer {
    background-image: url(../../assets/images/footer-bg.png);
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 25px;
}

.footer-logo>a>img {
    border-bottom: 1px solid #ffd672;
}

.footer-aboutus-text>p {
    line-height: 26px;
}

.footer-links>h2 {
    font-size: clamp(1rem, 5vw, 1.125rem);
    border-bottom: 1px solid #ffd672;
    display: inline-block;
}

.footer-links {
    border-left: 1px solid #ffd672;

}

.footer-links-list>li>a {
    transition: all .4s ease;
}

.footer-links-list>li>a {
    color: #fff;
}

.footer-links-list>li:hover>a {
    color: #000 !important;
    padding: 0px 2px;
}

/******************
8. contact-me page 
*****************/
.contactme-form-wrap {
    margin-bottom: 140px;
}

.main-menu-bookbtn>span {
    background-image: var(--all-icons);
    background-position: -60px -43px;
    background-repeat: no-repeat;
    background-color: #353535;
    width: 40px !important;
    display: inline-block;
    height: 36px;
    padding-left: 25px;
    background-size: auto;
    text-align: center;
    border: 1px solid #353535;
}

.main-menu-bookbtn>button {
    background-color: #000;
    color: #fff;
    padding: 0px 16px;
    font-size: 22px;
}

.main-menu-bookbtn>button:active {
    background-color: #646464 !important;
    color: #fff !important;
    border-color: #646464 !important;
}


.contactme-banner {
    background-image: url(../../assets/images/contact-me-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 70px;
    padding-bottom: 110px;
    box-shadow: 0px 8px 8px 0px #a9a9a969;
}

.contactme-banner-titile>h2 {
    font-weight: 600;
    background-color: #ebebeb;
    width: fit-content;
    margin: auto;
    padding: 3px 25px;
    border: 1px solid #b3b3b3;
}

.contact-list>li {
    padding: 35px 10px 35px 10px;
    box-shadow: 0px 0px 10px 6px #5b8a3929;
}

/* .contact-list>li:nth-child(0)>a {
    background-image: var(--all-icons);
    background-position: -16px -103px;
    background-color: inherit;
    background-repeat: no-repeat;
    width: 50px;
    height: 60px;
    padding-left: 85px;
    background-size: auto;
    text-align: center;
    color: #000;
    white-space: pre;
    text-decoration: none;
    align-items: center;
    display: flex;
    font-size: 18px;
} */

.contact-list>li:nth-child(1)>a {
    background-image: var(--all-icons);
    background-position: -121px -103px;
    background-color: inherit;
    background-repeat: no-repeat;
    width: 50px;
    height: 60px;
    padding-left: 85px;
    background-size: auto;
    text-align: center;
    color: #000;
    white-space: pre;
    text-decoration: none;
    align-items: center;
    display: flex;
    font-size: 18px;
}

.contactme-form-wrap {
    margin-top: 40px;
}

.contactme-form {
    box-shadow: 0px 0px 7px 6px #5b8a3929;
    padding: 15px 20px 20px 20px;
}

.contactme-form>h2 {
    font-size: clamp(1.375rem, 5vw, 1.75rem);
    font-weight: 600;
    margin-bottom: 20px;
}

.contactme-form-email>span,
.contactme-form-name>span,
.contactme-form-phone>span,
.contactme-form-subject>span {
    background-color: inherit;
    height: 36px;
    border: 1px solid #5b8b3994;
}

.contactme-form-message>textarea {
    border: 1px solid #5b8b3994;
}

.contactme-form-email>input,
.contactme-form-name>input,
.contactme-form-phone>input,
.contactme-form-subject>input {
    font-size: 14px;
    padding: 5px 5px 5px 10px;
    background-color: inherit;
    color: #c0c0c0;
    height: 38px;
    border: 1px solid #5b8b3994;
}

.contactme-form-textarea>textarea {
    font-size: 14px;
    padding: 0px 5px;
    height: 130px;
    background-color: inherit;
    color: #8d8d8d;
    border: 1px solid #5b8b3994;
}

.contactme-form-name>span {
    background-image: var(--all-icons);
    background-position: -398px -1px;
    background-color: inherit;
    background-repeat: no-repeat;
    width: 46px;
    height: 38px;
    padding-left: 25px;
    background-size: auto;
    text-align: center;
}

.contactme-form-email>span {
    background-image: var(--all-icons);
    background-position: -131px -42px;
    background-color: inherit;
    background-repeat: no-repeat;
    width: 46px;
    height: 38px;
    padding-left: 25px;
    background-size: auto;
    text-align: center;
}

.contactme-form-phone>span {
    background-image: var(--all-icons);
    background-position: -204px -42px;
    background-color: inherit;
    background-repeat: no-repeat;
    width: 46px;
    height: 38px;
    padding-left: 25px;
    background-size: auto;
    text-align: center;
}

.contactme-form-subject>span {
    background-image: var(--all-icons);
    background-position: -265px -43px;
    background-color: inherit;
    background-repeat: no-repeat;
    width: 46px;
    height: 38px;
    padding-left: 25px;
    background-size: auto;
    text-align: center;
}

/******************
9. service-rate page 
*****************/
.btn-appointment-serivce-page:after {
    content: '';
    position: absolute;
    background-image: var(--all-icons);
    background-position: -343px -51px;
    background-repeat: no-repeat;
    width: fit-content;
    height: 22px;
    padding-left: 22px;
    background-size: auto;
    text-align: center;
    margin-left: 11px;

}

.btn-appointment-serivce-page {
    background-color: rgba(255, 255, 255, .8);
    color: #000;
    border-radius: 5px;
    border: 1px solid #000;
    padding: 10px 37px 10px 10px;
    transition: all 0.3s ease;
    position: relative;

}

.btn-appointment-serivce-page:hover {
    background-color: #68bb50;
    color: #fff;
    border: 1px solid #68bb50;
}

.service-rate-wrap {
    margin-top: 30px;
    margin-bottom: 150px;
}

.dog-minute-bg1 {
    background-color: #68bb50 !important;
}

.dog-minute-bg2 {
    background-color: #44982b !important;
}

.dog-minute-bg3 {
    background-color: #186f32 !important;
}

.dog-minute-bg4 {
    background-color: #0a4945 !important;
}

.dog-price-shape1 {
    position: relative;
    padding-top: 25px;
}

.dog-price-shape1>img {
    position: absolute;
    top: -3px;
    left: 0;
    right: 0;
    width: 100%;
}

.dog-minute {
    font-size: clamp(1.125rem, 3vw, 1.25rem);
    color: #fff;
    font-weight: 500;
}

.dog-walk-title>img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 2px solid #a9dd85;
}

.dog-walk-title>span {
    font-size: clamp(1.2rem, 3vw, 1.875rem);
    display: inline-block;
    margin-left: 10px;
}

.dog-price {
    font-size: 16px;
}

.dog-number {
    font-size: clamp(1rem, 5vw, 1.125rem);
}

.dog-walking {
    padding: 15px;
    background: #fff;
    box-shadow: 0px 0px 7px 4px #ebebeb;
    height: 340px;
}

.cat-walking {
    padding: 15px;
    background: #fff;
    box-shadow: 0px 0px 7px 4px #ebebeb;
    height: 470px;
}

/* .dog-walk-table>.table>tbody>tr:last-child {background-color: #fff!important;} */

.dog-walk-table>.table>thead>tr>th {
    padding: 0px;
}

.dog-walk-table>.table>tbody>tr {
    background-color: #3fb161;
}

.dog-walk-table>.table>tbody>tr>td {
    vertical-align: middle;
}

.dog-walk-table>.table>tbody>tr:nth-child(odd) {
    background-color: #c6e8d0;
}

.green-light {
    background-color: #ddf2e4 !important;
}

.green-light-1 {
    background-color: rgba(141, 213, 163, .7) !important;
}

/* house visit table css */
.house-visit-table>.table>thead>tr>th {
    padding: 0px;
}

.house-visit-table>.table>tbody>tr {
    background-color: #5fc6c0;
}

.house-visit-table>.table>tbody>tr>td {
    vertical-align: middle;
}

.house-visit-table>.table>tbody>tr:nth-child(odd) {
    background-color: #cfeeec;
}

.house-minute-bg1 {
    background-color: #00738d !important;
}

.house-minute-bg2 {
    background-color: #ce5814 !important;
}

.house-minute-bg3 {
    background-color: #3fb161 !important;
}

.house-minute-bg4 {
    background-color: #ce5814 !important;
}

.house-visit-shape1 {
    position: relative;
    padding-top: 27px;
}

.house-visit-shape1>img {
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    width: 100%;
}

/******************
10. policy page 
*****************/
.policy-wrap {
    margin-top: 20px;
    padding-bottom: 175px;
}

.mw-100 {
    min-width: 100px;
}

/*.edit-profile-block {
    margin-bottom: 180px;
}*/
.edit-profile-title>h2 {
    font-size: clamp(1.375rem, 5vw, 1.75rem);
    font-weight: 600;
    margin-bottom: 20px;
}

.changee-mailblock {
    width: 40%;
    padding: 40px 15px;
    margin: auto;
    box-shadow: 0px 0px 19px 1px #d9d9d9;
    border-radius: 5px
}

.change-password-block {
    width: 40%;
    padding: 30px 15px;
    margin: auto;
    box-shadow: 0px 0px 19px 1px #d9d9d9;
    border-radius: 5px
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-container>img {
    width: 550px;
    margin: auto;
    display: block;
}

.welcome-text {
    display: inline-flex;
    flex-direction: column;
    line-height: 0px;
    vertical-align: middle;
    margin-right: 10px;
}

.welcome-name {
    display: block;
    margin-top: 14px;
}

.title-my-booking {
    font-size: 28px;
    font-weight: 500;
    background-color: #5b8a39e3;
    width: fit-content;
    margin: auto;
    padding: 10px 36px;
    color: #fff;
    border-radius: 7px;
}

.my-booking-table {
    margin-top: 20px;
    margin-bottom: 40px;
}

.reschedule-form {
    box-shadow: 0px 0px 8px 4px #ebebeb;
    border-radius: 8px;
}

.all-booking-table {
    /*margin-bottom: 180px;*/
}

.user-listing-block {
    margin-top: 20px;
    /*    padding-bottom: 220px;
   margin-bottom: 150px;*/
}



input[type="date"]::-webkit-calendar-picker-indicator {
    /* color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block; */
    background-image: var(--all-icons);
    background-position: -190px 2px;
    background-repeat: no-repeat;
    width: 40px;
    height: 36px;
    /* right:0; */
    /* padding-left: 25px; */
    background-size: auto;
    text-align: center;
    /* background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat;
    width: 20px;
    height: 20px; 
    border-width: thin;*/
}

.btn-flat {
    background-color: red;
    color: white;
    float: right;
}

.btn-xxs {
    padding: 0 .35rem;
    font-size: 0.8rem;
}

.error {
    color: red;
    font-size: 0.8rem;
}

.min-height-400 {
    min-height: 400px;
}

.min-height-500 {
    /*min-height: 520px;*/
    min-height: 555px;
}


.min-height-600 {
    min-height: 600px;
}

.min-height-700 {
    min-height: 700px;
}

.min-height-800 {
    min-height: 800px;
}

.min-height-900 {
    min-height: 900px;
}

.min-height-1000 {
    min-height: 900px;
}

.min-height-1100 {
    min-height: 900px;
}

.bg-white {
    background-color: "#ffffff" !important;
    border-top: 0px !important;
}

/*[data-amplify-authenticator] {
    display: grid;
    width: 100%;
    margin-bottom: 160px;
}*/

.memeber-area>h1 {
    background-color: #ffffff;
    text-align: center;
    width: fit-content;
    margin: auto;
    padding: 4px 30px;
    box-shadow: 0px 0px 5px 4px #5b8b3959;
    border-radius: 4px;

}

.reschedule-form {
    box-shadow: 0px 0px 8px 4px #ebebeb;
    border-radius: 8px;
}

.btn-disabled:disabled {
    /*background-color: #000;
    display: inline-block;
    height: 34px;
    line-height: 25px;
    padding: 0px 10px;*/
}


.next-btn:focus {
    box-shadow: inherit !important;

}

.next-btn span {
    font-size: 26px;
    color: #ffff;
    line-height: 0;
    vertical-align: initial;
}

.confirmation-btn-yes {
    background-color: #20bd57;
    color: #fff;
    width: 40%;
}

.confirmation-btn-yes:hover {
    background-color: #000;
    color: #fff;
}

.confirmation-btn-no {
    background-color: #ff0000;
    color: #fff;
}

.confirmation-btn-no:hover {
    background-color: #000;
    color: #fff;
}

.modal-footer-confirm {
    justify-content: space-evenly;
}

.modal-header-confirm {
    background-color: #0ba1b1;
    color: #fff
}

.toast-header {
    background-color: #f5f5f5;
    color: #000;
}

.toast-body {
    /* background-color: #0f9b67; */
    color: #fff;
}

.all-booking-table>thead>tr>th {
    padding: 4px 10px;
}

.all-booking-table>tbody>tr>td:nth-child(2) {
    border-left-width: var(--amplify-components-table-data-border-width);
}



.all-user-table>tbody>tr>td:nth-child(2) {
    border-left-width: var(--amplify-components-table-data-border-width);
}






































/* responsive start */
@media (min-width:1340px) {
    .bannerimmg {
        width: 480px;
    }
}

@media (max-width:767px) {
    .bannerimmg {
        width: 100%;
    }
}

@media (min-width:1600px) {
    /*.footer-bg {
        position: absolute;
        top: -225px;
        left: 0;
        right: 0;
        width: 100%;
    }*/
}

/*** responsive 1199 start  ***/
@media (max-width: 1199px) {


    .services>img {
        position: absolute;
        top: 90px;
        right: 0;
        width: 465px;
    }

    .services {
        padding: 5px 0px 25px;
    }

    .footer-bg {
        position: absolute;
        bottom: 248px
    }

    .gallery>li>a {
        width: auto;
    }

    .testimonial-content>p:last-child {
        font-size: 22px;
        color: #000;
        margin-bottom: 20px;
        line-height: 25px;
    }

    .slider-titile {
        padding-top: 0px;
    }

    .header-top-bar-btn>button:nth-child(1) {
        background-image: inherit;
        padding: 0px;
        width: 70px;
        height: 30px;
        padding-left: 0px;
    }

    .header-top-bar-btn>button:nth-child(2) {
        background-image: inherit;
        padding: 0px;
        width: 70px;
        height: 30px;
        padding-left: 0px;
    }

    .header-call-btn {
        background-image: inherit;
        padding: 5px 12px 5px 10px;
    }


    .testimonial-content {
        padding: 30px 7px;
    }

    .aboutus-wrap {
        margin-top: 45px;
        margin-bottom: 160px;
    }

    .dog-price-shape1>img {
        position: absolute;
        top: -8px;
        left: 0;
        right: 0;
        width: 100%;
    }

    .service-rate-wrap {
        margin-top: 30px;
        margin-bottom: 120px;
    }

    .policy-wrap {
        margin-top: 20px;
        padding-bottom: 125px;
    }
}

/*** responsive 1024 start  ***/
@media (max-width: 1024px) {
    .service-rate-wrap {
        margin-top: 30px;
        margin-bottom: 110px;
    }
}

/*** responsive 991 start  ***/
@media (max-width: 991px) {
    .navbar-nav {
        margin-top: 10px !important;
    }

    .navbar-nav>li {
        text-align: left;
    }

    .navbar-nav>li>a {
        color: #000 !important;
        padding-left: 15px !important;
    }

    .change-password-block {
        width: 70%;
    }

    .changee-mailblock {
        width: 60%;
    }

    .services-img {
        border-radius: 50%;
        width: 160px;
        height: 160px;
    }

    .services>img {
        display: none;
    }

    .services {
        margin-top: 40px;
    }

    .services-wrap {
        width: 100%;
    }

    .gallery-col-one {
        padding-top: 0px;
    }

    .gallery-col-two {
        padding-top: 0px;
    }

    .gallery-col-three {
        padding-top: 0px;
    }

    .gallery>li>a {
        width: 310px;
    }

    .footer-bg {
        position: absolute;
        bottom: 455px;
    }

    .contactme-form-wrap {
        margin-bottom: 90px;
    }

    .testimonial-content>p:nth-child(1),
    .testimonial-content>p:nth-child(3) {
        font-size: 36px;
        color: #bfbfbf;
        margin-bottom: 5px;
        line-height: 0;
    }

    .aboutus-wrap {
        margin-top: 30px;
        margin-bottom: 120px;
    }

    .dog-price-shape1>img {
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        width: 100%;
    }

    .service-rate-wrap {
        margin-top: 30px;
        margin-bottom: 120px;
    }

    .policy-wrap {
        margin-top: 20px;
        padding-bottom: 106px;
    }

    .dog-walking {
        height: inherit;
    }

    .all-user-table {
        display: block;
    }

    .all-booking-table {
        display: block;
    }

    .catWalkingMarginTop {
        margin-top: 75px;
    }
    .discountImg {
        width: 100% !important;
      }
}

/*** responsive 767 start  ***/
@media (max-width: 767px) {
    .all-user-table {
        display: inherit;
    }

    .my-booking-table {
        margin-top: 20px;
    }

    .all-booking-table {
        display: inherit;
    }

    .change-password-block {
        width: 100%;
        padding: 20px 15px;
    }

    .changee-mailblock {
        width: 80%;
    }

    .services>img {
        display: none;
    }

    .services-wrap {
        width: 100%;
    }

    .facts-img>img:nth-child(1) {
        width: 190px;
    }

    .footer-bg {
        position: absolute;
        bottom: 508px;
    }

    .contactme-form-wrap {
        margin-bottom: 70px;
    }

    .contact-list>li {
        padding: 10px 10px 10px 10px;
    }

    .gallery>li>a {
        width: inherit;
    }

    .header-top-bar-btn>button:nth-child(1),
    .header-top-bar-btn>button:nth-child(2) {
        background-image: inherit;
        padding: 0px;
        width: 58px;
        height: 27px;
        padding-left: 0px;
        font-size: 12px;
    }

    .header-call-btn {
        padding: 4px 6px 3px 6px;
        font-size: 12px;
    }

    .header li:last-child>a>div {
        width: 125px;
    }

    .header li:last-child>a {
        margin-top: 0px;
    }

    .aboutus-block>img {
        width: 270px;
    }

    .service-rate-wrap {
        margin-top: 30px;
        margin-bottom: 90px;
    }

    .policy-wrap {
        margin-top: 20px;
        padding-bottom: 75px;
    }

    .all-booking-table>thead {
        display: none;
    }


    .all-booking-table {
        display: block !important;
        border: inherit !important;
    }

    .all-booking-table>thead {
        display: none;
    }

    .all-booking-table>tbody {
        diplay: block;
    }

    .all-booking-table>tbody>tr {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        text-align: left;
        padding: 6px 0px;
    }

    .all-booking-table>tbody>tr>td {
        word-wrap: break-word;
        display: block;
        width: 49%;
        border-left: 0;
        border-right: 0;
        text-align: left;
        border-bottom: 0px;
        padding: 6px 5px;
        font-size: 14px;
    }

    .all-booking-table>tbody>tr>td:last-child {
        display: block;
        width: 100%;
        border-left: 0;
        border-right: 0;
        text-align: center !important;
        margin-bottom: 5px;
    }

    .all-booking-table>tbody>tr>td:last-child>button {
        padding: 0px 8px;
    }

    .amplify-table__td {
        border-color: transparent !important;
    }

    .all-booking-table>tbody>tr:not(:first-child) {
        border-top: 1px solid #dbdbdb !important;
        padding-top: 10px;
        padding-bottom: 5px;
    }

    .btn-disabled:disabled {
        width: 45px;
    }

    .next-btn>span>img {
        width: 45px;
    }

    .next-btn span {
        font-size: unset;
    }


    .all-user-table>thead {
        display: none;
    }

    .all-user-table {
        display: block !important;
        border: inherit !important;
    }

    .all-user-table>thead {
        display: none;
    }

    .all-user-table>tbody {
        diplay: block;
    }

    .all-user-table>tbody>tr {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        text-align: left;
        padding: 6px 0px;
    }

    .all-user-table>tbody>tr>td {
        word-wrap: break-word;
        display: block;
        width: 49%;
        border-left: 0;
        border-right: 0;
        text-align: left;
        border-bottom: 0px;
        padding: 6px 5px;
        font-size: 14px;
    }

    .all-user-table>tbody>tr>td:last-child {
        display: block;
        width: 100%;
        border-left: 0;
        border-right: 0;
        text-align: right !important;
        margin-bottom: 5px;
    }

    .all-user-table>tbody>tr>td:last-child>button {
        padding: 0px 8px;
    }

    .amplify-table__td {
        border-color: transparent !important;
    }

    .all-user-table>tbody>tr:not(:first-child) {
        border-top: 1px solid #dbdbdb !important;
        padding-top: 10px;
        padding-bottom: 5px;
    }












}

/*** responsive 575 start  ***/
@media (max-width: 575px) {
    .mb-90 {
        margin-bottom: 20px;
    }

    .welcome-text {
        margin-right: 8px;
        font-size: 12px;
    }

    .changee-mailblock {
        width: 100%;
    }

    .testimonial-img {
        width: 150px;
        margin: auto auto 25px auto;
        display: block;
        height: 150px;
    }

    .footer-bg {
        display: none;
    }

    .contactme-form-wrap {
        margin-bottom: 20px;
    }

    .facts-img>img:nth-child(1) {
        width: 130px;
    }

    .facts-img>img:nth-child(2) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60px;
    }

    .gallery {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .gallery>li {
        overflow: hidden;
        width: 48%;
        margin: 1%;
    }

    .gallery>li>a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 165px;
        width: 100%;
    }

    .gallery-wrap {
        margin-bottom: 20px;
    }

    .aboutus-wrap {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .dog-price-shape1>img {
        position: absolute;
        top: -8px;
        left: 0;
        right: 0;
        width: 100%;
    }

    .policy-wrap {
        margin-top: 20px;
        padding-bottom: 10px;
    }

    .service-rate-wrap {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .testimonial-content>p:nth-child(1),
    .testimonial-content>p:nth-child(3) {
        display: none;
    }

    .testimonial-content {
        padding: 4px 7px;
    }

    .home-form-label {
        font-size: 16px;
    }

    .home-banner {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .gallery>li>a>img {
        width: 100%;
    }

    .testimonial-wrap {
        margin-bottom: 20px;
    }




}

@media (min-width: 767px) {
    .main-menu {
        height: 36px;
    }

    .d-none-md {
        display: none;
    }
}

@media (min-width: 48em) {
    .header li {
        float: left;
        margin-right: 3px;
    }

    /* .header li a {
      padding: 6px 20px;
    } */
    .header .menu {
        clear: none;
        max-height: none;
    }

    .header .menu-icon {
        display: none;
    }
}

.color-white {
    color: #FFF;
}

.font12 {
    font-size: 12pt
}

.font10_5 {
    font-size: 10.5pt
}

.bgWhite {
    background-color: white
}

.align-justify {
    text-align: justify
}

.rbc-calendar {
    min-height: 580px;
}

@media (min-width:992px) {
    .boot-btn-add {
        position: absolute;
        right: 0px;
        top: 0px;
    }
}

.home-middle-text {
    font-size: 16px;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.test12 {
    border: 3px solid #0a4945;
    vertical-align: middle;

}

.discountImg {
    width: 400px;
}