.Loader,
.Loader:before,
.Loader:after {
  border-radius: 50%;
}

.Loader {
  color: #521751;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.Loader:before,
.Loader:after {
  position: absolute;
  content: '';
}

.Loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}

.Loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}

@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/* create the paw prints */
.pad {
  width: 25px;
  height: 27px;
  background-color: #654321;
  -webkit-border-radius: 63px 63px 63px 63px / 108px 108px 72px 72px;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  display: block;
  position: absolute;
}

.large {
  width: 70px;
  height: 80px;
  transform: rotate(80deg);
  left: 100px;
  top: 50px;
}

.small-1 {
  transform: rotate(50deg);
  left: 145px;
  top: 28px;
}

.small-2 {
  transform: rotate(65deg);
  left: 174px;
  top: 50px;
}

.small-3 {
  transform: rotate(98deg);
  position: absolute;
  left: 178px;
  top: 87px;
}

.small-4 {
  transform: rotate(140deg);
  position: absolute;
  left: 158px;
  top: 117px;
}

/* position and animate */


.paw-print-1 {
  scale:20%;

  position: absolute;
  /* left: 75px; */
  top: 250px;

}


.paw-print-2 {
  scale: 50%;
  opacity: 0;
  position: absolute;
  left: 195px;
  top: 280px;
  transform: rotate(-10deg);
  -webkit-animation: walk 3s linear infinite 0.25s;
  animation: walk 3s linear infinite
    /*delay*/
    0.25s;
}

.paw-print-3 {
  scale: 50%;
  opacity: 0;
  position: absolute;
  left: 225px;
  top: 185px;
  transform: rotate(-15deg);
  -webkit-animation: walk 3s linear infinite 0.5s;
  animation: walk 3s linear infinite 0.5s;
}

.paw-print-4 {
  scale: 50%;
  opacity: 0;
  position: absolute;
  left: 340px;
  top: 285px;
  transform: rotate(-30deg);
  -webkit-animation: walk 3s linear infinite 0.75s;
  animation: walk 3s linear infinite 0.75s;
}

.paw-print-5 {
  opacity: 0;
  position: absolute;
  left: 725px;
  top: 50px;
  transform: rotate(10deg);
  -webkit-animation: walk 3s linear infinite 1s;
  animation: walk 3s linear infinite 1s;
}

.paw-print-6 {
  opacity: 0;
  position: absolute;
  left: 875px;
  top: 200px;
  transform: rotate(10deg);
  -webkit-animation: walk 3s linear infinite 1.25s;
  animation: walk 3s linear infinite 1.25s;
}

.paw-print-7 {
  opacity: 0;
  position: absolute;
  left: 1075px;
  top: 50px;
  transform: rotate(20deg);
  -webkit-animation: walk 3s linear infinite 1.5s;
  animation: walk 3s linear infinite 1.5s;
}

.paw-print-8 {
  opacity: 0;
  position: absolute;
  left: 1150px;
  top: 250px;
  transform: rotate(10deg);
  -webkit-animation: walk 3s linear infinite 1.75s;
  animation: walk 3s linear infinite 1.75s;
}

/* walking effect animation keyframes */

@-webkit-keyframes walk {
  25% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes walk {

  /* appear */
  25% {
    opacity: 1;
  }

  /* disappear */
  100% {
    opacity: 0;
  }
}

.spinner-container {
  margin: 0px auto;
}